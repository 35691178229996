body {
  margin: 0;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
.container {
  display: flex;
  background-color: #f4ede7;
  margin: 0;
}

.border {
  width: 27px;
  background: url("https://dailyhesab.storage.iran.liara.space/border-block.png");
  background-size: contain;
  background-color: #f4ede7;
  margin-bottom: 10px;
}

.border.right {
  border-left: 10px solid #9b002c;
  rotate: 180deg;
}

.border.left {
  border-left: 10px solid #9b002c;
}

.logo img {
  width: 96px;
  height: auto;
  margin-top: 2em;
}
.tabs {
  display: flex;
  overflow-x: scroll;
}

.menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f4ede7;
}

.menu ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding: 0;
}
.menu li {
  margin-top: -10px;
  font-family: IranNastaliq, "IranNastaliq";
  list-style: none;
  flex-basis: calc(100% / 3);
  text-align: center;
  box-sizing: border-box;
  font-size: 22px;
  color: #dbb026;
  cursor: pointer;
}
.menu li.selected {
  color: #8a0403;
}

.content {
  font-family: "B Nazanin";
  width: 80%;
}

.item {
  margin: 0 14px;
}

.item-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.item-details .name {
  font-size: 26px;
}
.item-details .price {
  font-size: 26px;
}

.item .description {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  word-break: break-word;
}

.item .description p {
  margin-top: -24px;
}

.telephones {
  margin-top: 0.3em;
  color: #9b002c;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  line-height: 1.1;
}

.telephones > div:nth-last-child(1) {
  font-size: 36px !important;
}

.additional-descriptions {
  margin-top: 0.5em;
  margin-bottom: 2em;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
}
